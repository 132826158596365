<template>
  <div>
    <div v-if="page !== 'leadDetail'">
      <v-select
        v-model="selectBroker"
        dense
        :items="getContactBrokers(contactBrokers.ContactsList)"
        :label="
          selectBroker !== null
            ? selectBroker.name
            : language == 'spanish'
            ? 'Selecciona un asesor'
            : 'Select broker'
        "
        single-line
        item-text="name"
        return-object
        @input="selectedBroker(selectBroker)"
        :disabled="disabled"
      >
        <template v-slot:prepend>
          <v-icon>mdi-account</v-icon>
        </template>
      </v-select>
    </div>
    <div v-else>
      <v-select
        :items="getContactBrokers(contactBrokers.ContactsList)"
        :label="selected === null ? 'No asignado' : selected.name"
        v-model="selected"
        item-text="name"
        return-object
        @input="selectedBroker(selected)"
      >
        <template v-slot:prepend>
          <v-icon>mdi-account</v-icon>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: {
    page: {
      type: String,
      default: ""
    },
    language: {
      type: String,
      default: "spanish"
    }
  },
  data() {
    return {
      role: localStorage.getItem("user_role"),
      innerLoading: null,
      selectBroker: null,
      selected: null,
      disabled: false,
      asesorIgual: false
    };
  },
  watch: {
    selected(newValue, oldValue) {
      if (oldValue !== null) {
        if (newValue._id === oldValue._id) {
          this.asesorIgual = true;
        } else {
          this.asesorIgual = false;
        }
      } else {
        this.asesorIgual = false;
      }
    },
    lead() {
      this.selected = this.getCurrentBroker(this.lead);
    }
  },
  computed: {
    ...mapState({
      contactBrokers: state => state.contacts.contactList,
      loading: state => state.leads.loading,
      lead: state => state.leads.actualItem,
      leadsPagination: state => state.leads.leadsPagination
    })
  },
  mounted() {
    this.selected = this.getCurrentBroker(this.lead);
  },
  created() {
    this.brokerAgent();
  },
  methods: {
    ...mapActions({
      fetchUpdatelLead: "leads/fetchUpdatelLead",
      fetchActualLead: "leads/fetchActualLead"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    brokerAgent() {
      if (this.role === "broker") {
        /*  this.disabled = true; */
        const getIdBroker = localStorage.getItem("contactId");
        let SearchBroker = this.getContactBrokers(
          this.contactBrokers.ContactsList
        );

        SearchBroker = SearchBroker.filter(sl => {
          if (sl._id === getIdBroker) {
            return sl;
          }
        });
        this.selectBroker = SearchBroker[0];
        this.selectedBroker(SearchBroker[0]);
      }
    },
    getContactBrokers(items) {
      if (items) {
        if (this.role === "broker") {
          const getIdBroker = localStorage.getItem("contactId");
          let search = items.filter(l => {
            if (l._id === getIdBroker) {
              return l;
            }
          });
          return search;
        } else {
          return items.filter(l => {
            return l.status !== 0;
          });
        }
      } else {
        return [];
      }
    },
    validateNameLead(lead) {
      if (lead) {
        if (lead.trim() === "") {
          return "Sin nombre";
        } else {
          return lead;
        }
      } else {
        return "Sin nombre";
      }
    },
    getCurrentBroker(l) {
      if (l.internal_admin && l.internal_admin.name) {
        return l.internal_admin;
      } else if (l.internal_broker && l.internal_broker.name) {
        return l.internal_broker;
      } else {
        return { name: "No asignado", role: "" };
      }
    },
    selectedBroker(item) {
      if (this.page !== "leadDetail" && item) {
        this.getContactBrokerOption(item);
      } else {
        if (item.role === "admin") {
          this.lead.internal_admin = item;
          this.getContactBrokerOption(item);
        } else if (item.role === "broker") {
          this.lead.internal_broker = item;
          this.getContactBrokerOption(item);
        } else {
          return null;
        }
      }
    },
    getContactBrokerOption(option) {
      if (this.page && this.page === "realEstate") {
        if (this.page !== "leadDetail" && option) {
          this.$emit("getOption", option);
          this.$store.commit("leads/updateActualLead", {
            internal_broker: option
          });
        } else {
          if (option) {
            this.$store.commit("leads/updateActualLead", {
              internal_broker: option
            });
            this.sendNotificationAssignBroker(option._id, option.name);
          }
        }
      } else {
        let pref = JSON.parse(localStorage.getItem("preferences"));
        let path = pref && pref.showKanbanFirst != null && pref.showKanbanFirst;
        if (this.page !== "leadDetail" && option) {
          this.$emit("getOption", option);
          this.$store.commit("leads/updateActualLead", {
            page: `${this.page}${path ? "kanban" : "leads"}`,
            leadId: this.lead._id,
            internal_broker: option
          });
        } else {
          if (option) {
            this.$store.commit("leads/updateActualLead", {
              page: `${this.page}${path ? "kanban" : "leads"}`,
              leadId: this.lead._id,
              internal_broker: option
            });
            this.sendNotificationAssignBroker(option._id, option.name);
          }
        }
      }
    },
    sendNotificationAssignBroker(contactBrokerId, nameAssignBroker) {
      this.innerLoading = contactBrokerId;
      setTimeout(() => {
        let updateData = {
          leadId: this.lead._id,
          contactLeadId: this.lead.contact_lead_id,
          zones: this.lead.zones,
          lead: {
            contactBrokerId,
            nameAssignBroker,
            assinedPhase: this.lead.tracking_phase
          }
        };
        this.sendUpdateLead(updateData);
      }, 250);
    },
    sendUpdateLead(updateData) {
      let self = this;
      if (!this.asesorIgual) {
        self
          .fetchUpdatelLead(updateData)
          .catch(() => {
            self.$snotify.error(
              "Error!!!",
              `Ocurrio un problema actualizar el lead.`,
              {
                closeOnClick: true,
                timeout: 4000
              }
            );
          })
          .finally(() => {
            self.setLoading(false);
            setTimeout(() => {
              self.innerLoading = false;
            }, 1000);
            this.fetchActualLead(this.lead._id);
          });
      }
    }
  }
};
</script>

<style></style>
